import React, { useEffect, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/SEO/seo';
import { PostQueryQuery } from '../../gatsby-graphql';
import { LanguageSwitchContext } from '../components/LanguageSwitch';
import FlowPoster from '../components/blog/FlowPoster';
import slugify from 'slug';
import RelatedPosts from '../components/blog/RelatedPosts';

const PostDetails = (page: PageProps<PostQueryQuery>) => {
  const {
    slug,
    language,
    content,
    title,
    source,
    translations,
    seo_title,
    seo_description,
    featured_image,
    excerpt,
    published_at,
    author,
    categories,
  } = page.data.storychief || {};
  const [relatedPostsData, setRelatedPostsData] = useState<any>([]);

  useEffect(() => {
    const data = page.data.allStoryChief.edges.map((el) => el.node);
    setRelatedPostsData(data);
  }, []);

  const category = slugify(categories?.data![0]?.name || 'news');

  const translatedPosts: Record<string, string> = source?.data?.language
    ? { [source.data.language]: `/blog/${category}/${source.data.slug}` }
    : {};
  translations?.data?.forEach((t) => {
    if (t && t.language && t.slug) {
      translatedPosts[t.language] = `/blog/${category}/${t.slug}`;
    }
  });

  const LayoutWithTranslationLinks: React.FC = ({ children }) => (
    <LanguageSwitchContext.Provider value={{ languageToPath: translatedPosts }}>
      <Layout>{children}</Layout>
    </LanguageSwitchContext.Provider>
  );

  return (
    <div>
      <LayoutWithTranslationLinks>
        <Seo
          page={page}
          path={`${language == 'en' ? '' : '/' + language}/blog/${category}/${slug}`}
          language={language!}
          title={seo_title || `${title} - Flow Blog`}
          description={seo_description || excerpt}
          banner={featured_image?.data?.sizes?.regular}
        />
        <main className="post-page">
          <section className="section-1">
            <div className="container">
              <div className="section-1__content">
                <h1 className="section-1__title u-title-secondary">{title}</h1>
                <div className="section-1__info">
                  <div className="section-1__wrapper">
                    <div className="author">
                      <div className="author__name u-link-secondary">
                        {author?.data?.first_name} {author?.data?.last_name}
                      </div>
                    </div>
                    <div className="section-1__date">{published_at}</div>
                  </div>
                </div>
                <div className="post-content" dangerouslySetInnerHTML={{ __html: content! }} />
                <FlowPoster extended />
              </div>
            </div>
          </section>
          <RelatedPosts
            currentPostSlug={slug!}
            data={relatedPostsData}
            category={categories?.data![0]?.name!}
          />
        </main>
      </LayoutWithTranslationLinks>
    </div>
  );
};

export default PostDetails;

export const postDetailsQuery = graphql`
  query PostQuery($slug: String, $language: String!) {
    allStoryChief: allStorychief(sort: { fields: [published_at], order: DESC }) {
      edges {
        node {
          excerpt
          id
          language
          slug
          title
          featured_image {
            data {
              sizes {
                regular
              }
            }
          }
          categories {
            data {
              name
            }
          }
          author {
            data {
              first_name
              last_name
              profile_picture {
                data {
                  url
                }
              }
            }
          }
        }
      }
    }
    storychief: storychief(slug: { eq: $slug }) {
      slug
      language
      content
      excerpt
      featured_image {
        data {
          sizes {
            regular
          }
        }
      }
      categories {
        data {
          name
        }
      }
      seo_title
      seo_description
      title
      source {
        data {
          language
          slug
        }
      }
      translations {
        data {
          language
          slug
        }
      }
      published_at(formatString: "DD-MM-YYYY")
      author {
        data {
          first_name
          last_name
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
  }
`;
