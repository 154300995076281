import React, { useEffect, useState } from 'react';
import Post from './Post';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import slugify from 'slug';

export interface PostData {
  id: string;
  language: string;
  title: string;
  excerpt: string;
  slug: string;
  featured_image: {
    regular?: string;
    full?: string;
    large?: string;
  };
  categories: Array<{
    name: string;
  }>;
  author: {
    name: string;
    profile_picture: string;
  };
}

interface PostsProps {
  category: string;
  data: Array<any>;
  currentPostSlug: string;
}

const RelatedPosts = ({ category: categoryName, data, currentPostSlug }: PostsProps) => {
  const { i18n, t } = useTranslation('blog');
  const [filteredData, setFilteredData] = useState<PostData[]>([]);

  const filterRelatedPosts = (data: PostData[]) => {
    const filteredPosts = data.filter(({ language, categories, slug }) => {
      return (
        categories?.some((cat: any) => cat?.name === categoryName) &&
        language === i18n.language &&
        slug !== currentPostSlug
      );
    });
    const setDataHandler = (posts: PostData[]) => setFilteredData(posts.slice(0, 3));
    if (filteredPosts.length >= 3) {
      setDataHandler(filteredPosts);
      return;
    }
    // If category posts contain less than 4 items shuffle all posts and select 4 posts from them
    const shuffled = data
      .sort(() => 0.5 - Math.random())
      .filter((post) => post.slug !== currentPostSlug);
    setDataHandler(shuffled);
  };

  useEffect(() => {
    if (data.length > 0) {
      const languageSpecificData = data.map((post) => {
        return {
          id: post.id,
          language: post.language,
          featured_image: { ...post.featured_image?.data?.sizes },
          slug: post.slug,
          excerpt: post.excerpt,
          title: post.title,
          categories: post.categories?.data,
          author: {
            name: `${post.author?.data?.first_name} ${post.author?.data?.last_name}`,
            profile_picture: post.author?.data?.profile_picture?.data?.url,
          },
        };
      });
      filterRelatedPosts(languageSpecificData);
    }
  }, [data]);

  return (
    <section className="section-2">
      <div className="container">
        <div className="section-2__content">
          <h3 className="section-2__title">{t('Post.relatedPostsTitle', 'More related posts')}</h3>
          <div className="post-feed post-feed--related">
            {filteredData.map(
              ({ id, title, excerpt, featured_image, author, categories, slug }) => (
                <Post
                  isRelated
                  key={id}
                  title={title}
                  excerpt={excerpt}
                  image={featured_image.regular!}
                  author={author}
                  slug={`${i18n.language === 'en' ? '' : `/${i18n.language}`}/blog/${slugify(
                    categories[0].name
                  )}/${slug}`}
                  categories={categories}
                />
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RelatedPosts;
